import {
  AssetsBlockchainType,
  TransactionApiType,
  TransactionDirectionEnum,
  TransactionInfoType,
  TransactionTypeEnum,
} from "@/store/types";
import { isValidAddress } from "@/core/utils/transactions";
import { useDialogStore } from "@/store/dialog";
import { useWalletStore } from "@/store/wallet";
import { storeToRefs } from "pinia";
const { STORAGE_TR_KEY } = require("@/core/constants");

export const parseTransactions = (
  data: TransactionApiType[],
  assets: AssetsBlockchainType[],
  side: TransactionDirectionEnum,
  addProcessed?: boolean
): TransactionInfoType[] => {
  const seeds: string[] = [];
  const parsedMainData = data
    .filter(
      (item) =>
        item.description["transaction-type"] === "TransferAssetsTransaction"
    )
    .map((item) => {
      seeds.push(item.description.seed);
      return parseTransaction(item, assets, side);
    })
    .filter((item) => item != null) as TransactionInfoType[];

  if (addProcessed) {
    const sessionStorageTr = getTransactionsFromSessionStorage();
    sessionStorageTr.forEach((tr: TransactionInfoType) => {
      if (seeds.includes(tr.seed)) {
        removeTransactionFromSessionStorage(tr.seed);
        return;
      }
      parsedMainData.push(tr);
    });
  }
  return parsedMainData;
};
export const parseTransaction = (
  item: TransactionApiType,
  assets: AssetsBlockchainType[],
  side: TransactionDirectionEnum
): TransactionInfoType | null => {
  const assetCode = item.description.asset?.[0]?.code;
  if (assetCode == null) return null;
  const asset = assets.find((el) => el.code == assetCode);
  if (asset == null) return null;
  const token_name = asset.fullname;
  const token_fullname = asset.name;
  const amount = item.description.asset[0].amount;
  const address =
    side === TransactionDirectionEnum.SENT
      ? item.description.to
      : item.description.from;
  let type: TransactionTypeEnum = TransactionTypeEnum.TRANSFER;
  if (isValidAddress.usdt(address)) type = TransactionTypeEnum.WITHDRAW;

  return {
    id: item.description["transaction-id"],
    type,
    direction: side,
    block: Number(item.ID),
    token_name,
    token_fullname,
    token_code: assetCode,
    amount,
    status: item.type,
    to: item.description.to,
    from: item.description.from,
    address,
    date: +item.timestamp,
    seed: item.description.seed,
  };
};
export const addTransactionInSessionStorage = (
  transaction: TransactionInfoType
): void => {
  try {
    const currentStorage = sessionStorage.getItem(STORAGE_TR_KEY);
    if (!currentStorage) {
      sessionStorage.setItem(STORAGE_TR_KEY, JSON.stringify([transaction]));
      return;
    }
    const parsedStorage = JSON.parse(currentStorage);
    parsedStorage.unshift(transaction);
    sessionStorage.setItem(STORAGE_TR_KEY, JSON.stringify(parsedStorage));
  } catch (e) {
    console.error(e);
  }
};
export const getTransactionsFromSessionStorage = (): TransactionInfoType[] => {
  const currentStorage = sessionStorage.getItem(STORAGE_TR_KEY);
  if (!currentStorage) return [];
  return JSON.parse(currentStorage);
};
export const removeTransactionFromSessionStorage = (seed: string): void => {
  try {
    const currentStorage = sessionStorage.getItem(STORAGE_TR_KEY);
    if (!currentStorage) {
      return;
    }
    const parsedStorage = JSON.parse(currentStorage);

    sessionStorage.setItem(
      STORAGE_TR_KEY,
      JSON.stringify(
        parsedStorage.filter((el: TransactionInfoType) => el.seed !== seed)
      )
    );
  } catch (e) {
    console.error(e);
  }
};

export const useValidationWallet = () => {
  const dialogStore = useDialogStore();
  const { openDialog } = dialogStore;

  const walletStore = useWalletStore();
  const { getTotalBalance } = storeToRefs(walletStore);

  return () =>
    +getTotalBalance.value
      ? openDialog("validation-wallet")
      : openDialog("activation-wallet");
};
