import { RouteName } from "@/router/types";
import { isAuth } from "@/core/utils/auth";
import { RouteLocationRaw } from "vue-router";

export const authViewMiddleware = (next: (to?: RouteLocationRaw) => void) => {
  if (!isAuth()) {
    next();
  } else {
    next({ name: RouteName.WALLET });
  }
};

export const usersViewsMiddleware = (next: (to?: RouteLocationRaw) => void) => {
  if (isAuth()) {
    next();
  } else {
    next({ name: RouteName.HOME });
  }
};
